

































































































































































































































































































































































.send-alarm {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
}
